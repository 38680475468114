<template>
  <v-card flat class="px-1 mt-1">
    <v-card-text class="pa-0">
    <!-- <h3 v-if="projectDeviationObj.isProjectDeviation" class="pa-1 pl-2">{{$t('message.projects.project')}}: {{printProjectName}} -->
      <!-- <v-divider></v-divider> -->
      <h3 v-if="projectFullName && projectDeviationObj.projectId" class="pa-1 pl-2">{{$t('message.projects.project')}}: <router-link :to="`/projects/${projectDeviationObj.projectId}`">{{ projectFullName }}</router-link>
    </h3>
    </v-card-text>
    <compact-list :payload="payload" :key="reInit"></compact-list>
  </v-card>
</template>
<script>
import { hostAppApi } from '../../plugins/axios_settings'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [projectMixin],
  data () {
    return {
      payload: {
        module: '',
        moduleTitle: 'message.layout.deviation',
        addBtnTo: '',
        pagination: {},
        totalCount: 0,
        perPage: 6,
        loading: false,
        editHandler: null,
        actionSize: '0', // for delete action
        list: [],
        items: [{
          value: 'deviation_number',
          class: 'mt-3 pa-0 pa-1',
          cols: '2',
          spanClass: 'body pl-2'
        }, {
          value: '',
          cols: '9',
          class: 'pa-0 pl-5',
          rowClass: 'mt-1',
          subItems: [{
            value: 'title',
            class: 'pa-0 pb-1',
            cols: '6',
            spanClass: 'body'
          }, {
            value: 'deviation_date',
            class: 'pa-0 pb-1',
            cols: '6',
            spanClass: 'subtitle'
          }, {
            value: 'type_text',
            class: 'pa-0 pb-1',
            cols: '6',
            spanClass: 'subtitle',
            isTranslatable: true
          }, {
            value: 'category_name',
            class: 'pa-0 pb-1',
            cols: '6',
            spanClass: 'subtitle'
          }, {
            value: 'location_name',
            class: 'pa-0 pb-1',
            cols: '5',
            spanClass: 'subtitle'
          }]
        }],
        storeIndex: 'DEVIATION_INDEX',
        isLivePagination: true,
        hasDelete: false,
        hasNoNext: true
      },
      MODULE: '',
      loading: false,
      reInit: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.DEVIATION_INDEX) ? this.$store.state.common.DEVIATION_INDEX : { itemsPerPage: 6, page: 1 },
      /* for deviation & project deviation */
      projectDeviationObj: {
        isProjectDeviation: false,
        projectId: 0
      },
      hostRef: this.$cookie.get('AxentivPwa_host-ref'),
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      /* #11888-OP */
      deviationTypes: [
        { text: 'message.deviation.deviations', value: 1 },
        { text: 'message.deviation.changeRequest', value: 2 },
        { text: 'message.deviation.hse', value: 3 }
      ],
      projectFullName: ''
    }
  },
  components: {
    'compact-list': () => import('@/components/CompactList.vue')
  },
  created () {
    this.MODULE = 'deviation'
    if (this.$route.name === 'project_deviation') {
      this.MODULE = 'projectdeviation'
      this.projectDeviationObj.isProjectDeviation = true
      this.projectDeviationObj.projectId = parseInt(this.$route.params.project_id)
      // this.getProject(parseInt(this.$route.params.project_id))
    }
    this.payload.editHandler = (item) => this.$router.push(this.projectDeviationObj.isProjectDeviation ? `/deviations/${item.id}?project_id=${this.projectDeviationObj.projectId}` : `/deviations/${item.id}`)
    this.payload.addBtnTo = this.projectDeviationObj.isProjectDeviation ? `/deviations/add?project_id=${this.projectDeviationObj.projectId}` : '/deviations/add'
  },
  mounted () {
    this.getListHandler()
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        const payload = { url: `deviation/${data.id}?user_id=${parseInt(this.hostUserId)}`, isSingle: true, module: this.MODULE, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getListHandler(this.pagination)
      }
    })
    // Load list on page
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
  },
  methods: {
    getListHandler (paginationObj) {
      const pagination = paginationObj || this.pagination
      this.payload.pagination.page = pagination.page
      this.payload.loading = true
      this.payload.module = this.MODULE
      const search = pagination.search ? pagination.search : ''
      const pageVal = pagination.page // search ? 1 : pagination.page
      const model = {
        start: ((pageVal - 1) * this.pagination.itemsPerPage),
        length: this.pagination.itemsPerPage,
        search: search
      }
      let url = `https://${this.hostRef}/api/deviation?only_items=1&start=${model.start}&length=${model.length}`
      if (search) url += `&search=${search}`
      if (this.projectDeviationObj.isProjectDeviation) url += `&project_id=${this.projectDeviationObj.projectId}`
      hostAppApi.get(url)
        .then(response => {
          let { data, recordsFiltered, projectFullName } = response.data
          this.payload.total = recordsFiltered
          const transformData = {
            date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
          }
          data = this.$formatter.formatListModel(data, transformData)
          data.forEach(deviation => {
            const obj = this.deviationTypes.find(x => x.value === deviation.deviation_type)
            deviation.type_text = obj ? obj.text : ''
          })
          const totalCount = Math.ceil(recordsFiltered / pagination.itemsPerPage)
          if (pagination.page === totalCount) this.payload.hasNoNext = true
          else this.payload.hasNoNext = false
          this.payload.list = data
          this.projectFullName = projectFullName
          if (this.reInit === 0) this.reInit++
        }).finally(() => {
          /* if (paginationObj) {
            const model = { type: 'BUILDING_INDEX', data: { page: paginationObj.page, itemsPerPage: paginationObj.itemsPerPage } }
            this.$store.commit('rememberOrder', model)
          } */
          this.payload.loading = false
        })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('loadList')
  }
}
</script>
